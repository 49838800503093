export const setVideoStatus = (id) => {
    let getVideoClickedArr = JSON.parse(
        localStorage.getItem("video_clicked_arr") || "[]"
    );

    getVideoClickedArr.push(id);

    const uniqueVideoClickedArr = getVideoClickedArr?.filter((item, index) => getVideoClickedArr.indexOf(item) == index);

    localStorage.setItem('video_clicked_arr', JSON.stringify(uniqueVideoClickedArr))

}