import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useCookies } from "react-cookie"
import classes from "./WishlistButton.module.css";
import { getSession } from 'next-auth/client'
import { initializeStore } from '/components/store/store'
import FormLoader from "/components/ui/FormLoader";
import { getReduxSession } from "/lib/sessionHandler"
import { getWebsiteID } from "/lib/common-functions"
import { AddToWishlistEvent, RemoveFromWishlist } from "/lib/ga";
import { getWebsiteURL } from "/lib/services/helpers/getWebsiteURL";

async function addWishlist(parentSku, sku) {
  const session = await getSession();
  const wishlistId = ((session?.user?.wishlists?.[0]?.id) ? session.user.wishlists[0].id : '')
  const res = await fetch("/api/add-wishlist", {
    body: JSON.stringify({ wishlistId: wishlistId, parent_sku: parentSku, sku: sku, accessToken: session?.accessToken }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  const data = await res.json();
  if (!res.ok) {
    return data;
  }

  return data;
}

async function removeWishlistItem(parentSku, sku, product, eventCommonObj) {
  let session = await getSession();
  let wishlistItemsIds = '';
  const wishlist = ((session.user && session.user.wishlists && session.user.wishlists[0]) ? session.user.wishlists[0] : '')
  if (wishlist && wishlist.items_v2 && wishlist.items_v2.items) {
    wishlist.items_v2.items.map(function (item, index) {
      if ((item.product.sku == sku) || item.product.sku == parentSku) {
        wishlistItemsIds = item.id
      }
    })
  }
  const wishlistId = ((wishlist && wishlist.id) ? wishlist.id : '')
  const res = await fetch("/api/remove-wishlist-item", {
    body: JSON.stringify({
      wishlistId: wishlistId,
      wishlistItemsIds: [wishlistItemsIds],
      accessToken: session.accessToken,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  const data = await res.json();
  if (!res.ok) {
    return data;
  }
  const productcategory = product?.variants?.[0]?.product?.primary_category || null

  RemoveFromWishlist(productcategory, product?.name, eventCommonObj)
  return data;
}


const WishlistButton = (props) => {
  const session = getReduxSession(true);
  const { product, parentSku, sku, name, eventCommonObj, ItemCategory3 } = props;

  const [showLoader, setShowLoader] = useState(false);
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore

  const router = useRouter();
  const [cookie, setCookie] = useCookies(["store"])
  let website_id = getWebsiteID(cookie);
  let prefixUrl = getWebsiteURL(website_id)
  const [wishlistCookie, setWishlistCookie] = useCookies(["wishlist_items"])

  const [toggleWishlist, setToggleWishlisted] = useState(false);
  const CUSTOMER_LOGIN_URL = `${prefixUrl}/customer/account/login`;

  useEffect(() => {
    let wishlist_items = (wishlistCookie?.wishlist_items) ? wishlistCookie.wishlist_items : []
    if (sku && wishlist_items.includes(sku)) {
      setToggleWishlisted(true)
    }
    if (parentSku && wishlist_items.includes(parentSku)) {
      setToggleWishlisted(true)
    }

  }, [wishlistCookie]);

  const handlerToggleFavourite = async () => {
    if (!session || session.length < 1) {
      setCookie('wishlist_sku', sku, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
      setCookie('wishlist_parent_sku', parentSku, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
      router.push(CUSTOMER_LOGIN_URL);
      return;
    }

    setShowLoader(true)

    const result = ((toggleWishlist) ? removeWishlistItem(parentSku, sku, product, eventCommonObj) : addWishlist(parentSku, sku));
    const response = async () => {
      try {
        const tmp = await result;

        setShowLoader(false)
        if (tmp.message) {
          dispatch(
            {
              type: 'RESPONSE_MSG',
              message: tmp.message,
              message_type: 'error',
            })
        } else {
          if (tmp?.data?.addProductsToWishlist) {
            AddToWishlistEvent(product, sku, props?.variantLabel, session, website_id, eventCommonObj, ItemCategory3, props?.productprice, props?.productSellPrice)
            setToggleWishlisted(true);
          } else {
            setToggleWishlisted(false);
          }
          const msg = !toggleWishlist
            ? "Product added to the wishlist"
            : "Product removed from the wishlist";
          dispatch(
            {
              type: 'RESPONSE_MSG',
              message: msg,
              message_type: 'success',
            })
        }
      } catch (error) {
        setShowLoader(false)
      }
    };
    response();
  };

  return (
    <>
      <FormLoader show={showLoader} />
      <FavoriteIcon
        className={`btnlistwish ${classes.btnWishlist} ${toggleWishlist ? `btnwishlistonclick  ${classes.btnwishlistonclick}` : classes.btnWishlistclick}`}
        onClick={handlerToggleFavourite}

      />
    </>
  );
};

export default WishlistButton;
